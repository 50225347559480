import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import ScrollMagic from "scrollmagic"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import Styles
import "../styles/partials/_modals.scss"
import "../styles/pricing.scss"

// Import Images
import traefik_logo from "../images/logos/traefik-proxy-logo.svg"
import traefikee_logo from "../images/logos/traefik-enterprise-logo.svg"
import customer__mozilla from "../images/pricing/customers/mozilla.svg"
import customer__condnast from "../images/pricing/customers/cond-nast.svg"
import customer__expedia from "../images/pricing/customers/expedia.svg"
import customer__talend from "../images/pricing/customers/talend.svg"
import customer__bell from "../images/pricing/customers/bell.svg"
import customer__mailchimp from "../images/pricing/customers/mailchimp.svg"
import customer__apple from "../images/pricing/customers/apple.svg"
import customer__bose from "../images/pricing/customers/bose.svg"
import customer__sainsbury from "../images/pricing/customers/sainsbury.svg"
import customer__creditsuisse from "../images/pricing/customers/credit-suisse.svg"
import customer__sysdig from "../images/pricing/customers/sysdig.svg"
import customer__ovh from "../images/pricing/customers/ovh-cloud.svg"
import customer__newrelic from "../images/pricing/customers/new-relic.svg"
import customer__holidaycheck from "../images/pricing/customers/holiday-check.svg"
import customer__virginia from "../images/pricing/customers/virginia-tech.svg"
import CloseIcon from "../images/inline-svgs/close-icon.svg"

interface Props {
  data: any
}

const PricingPage = ({ data, location }: Props) => {
  const [traefikModal, setTraefikModal] = useState(false)
  const [askQuestionModal, setAskQuestionModal] = useState(false)
  const [tableStickyFooter, setTableStickyFooter] = useState(false)

  useEffect(() => {
    // ScrollMagic make table footer sticky
    let controller = new ScrollMagic.Controller()

    let scene_table_1 = new ScrollMagic.Scene({
      triggerElement: "#pricing-anchor-start",
      triggerHook: 1,
    })
      .on("enter leave", function (event) {
        if (event.type == "leave") {
          setTableStickyFooter(false)
        } else {
          setTableStickyFooter(true)
        }
      })
      .addTo(controller)

    let scene_table_2 = new ScrollMagic.Scene({
      triggerElement: "#pricing-anchor-end",
      triggerHook: 1,
    })
      .on("enter leave", function (event) {
        if (event.type == "leave") {
          setTableStickyFooter(true)
        } else {
          setTableStickyFooter(false)
        }
      })
      .addTo(controller)

    // Load Hubspot forms in modals
    let script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/v2.js"
    script.onload = function () {
      hbspt.forms.create({
        portalId: "5814022",
        formId: "7b1b04ff-0d6f-40aa-9ff7-3442a1fb6bc2",
        target: "#traefikee-modal-content",
      })

      hbspt.forms.create({
        portalId: "5814022",
        formId: "7e8fab3d-7db5-4643-9f0c-3b1dd0923e4c",
        target: "#ask-us-question-modal-content",
      })
    }
    document.getElementsByTagName("head")[0].appendChild(script)

    // unmount
    return () => {
      controller.destroy(true)
    }
  }, [])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="pricing-page">
        {/* Pricing Header */}
        <section className="pricing-header page-header">
          <div className="wrapper-1200">
            <h1>Pricing</h1>
          </div>
        </section>

        {/* Pricing Main */}
        <section
          id="compare-open-source-vs-enterprise"
          className="pricing-main page-content-section"
        >
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Compare Open Source vs. Enterprise</h2>
              <p className="sub-title">
                Traefik Proxy offers ultimate flexibility and ease of use for
                individuals and teams running non-mission-critical applications.
              </p>
              <p className="sub-title">
                Traefik Enterprise brings out of the box high availability and
                security features necessary for mission critical application
                workloads, and includes 24/7 support for organizations.
              </p>
            </div>

            {/* Pricing Table */}
            <div className="pricing-main__comparison">
              <table id="pricing-table">
                <thead>
                  <tr>
                    <th>Features</th>
                    <th className="cell-traefik">
                      <img src={traefik_logo} alt="Traefik" />
                      <div className="category">Open Source</div>
                      <div className="pricing">Free</div>
                    </th>
                    <th className="cell-traefikee">
                      <img src={traefikee_logo} alt="Traefik Enterprise" />
                      <div className="category">Enterprise</div>
                      <div className="pricing">Annual Subscription</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>
                      All-in-one Ingress, Mesh and API management
                    </td>
                  </tr>
                  <tr id="pricing-anchor-start">
                    <td>Ingress management</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Service Mesh: service-to-service communication</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>API Gateway</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>API Portal</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>Traffic Management</td>
                  </tr>
                  <tr>
                    <td>HTTP, HTTP/2, TCP, UDP, Websockets, gRPC</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Mirroring</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Canary deployments</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Stickiness</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Active health checks</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Middlewares (circuit breakers, automatic retries,
                      buffering, response compression, headers)
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Custom middlewares through public and private plugins
                    </td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Distributed in-flight request limiting</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Distributed rate limiting (per-IP, per-host, per-header)
                    </td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>Availability &amp; Scalability</td>
                  </tr>
                  <tr>
                    <td>One line cluster deployment through CLI</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Controllers &amp; proxies resilience</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Scalability (adaptive scale out)</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Distributed in-flight request</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Backup &amp; Restore</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>Security</td>
                  </tr>
                  <tr>
                    <td>OpenID Connect (OIDC)</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>JWT authentication</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>LDAP authentication</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Open Policy Agent (OPA)</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>oAuth2 authentication</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>HMAC authentication</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Automatic HTTPS</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Distributed Let's Encrypt</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Unlimited Let's Encrypt certificates</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Multi-cluster Let’s Encrypt agent</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Custom rate limiting (Let’s Encrypt)</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  <tr>
                    <td>Custom certificates</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Encrypted cluster communication</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Vault (by Hashicorp) support</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>Observability</td>
                  </tr>
                  <tr>
                    <td>Cluster-wide dashboard</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Proxies health check</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Distributed tracing (Jaeger, Open Tracing, Zipkin)</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Real-time traffic metrics (Datadog, Grafana, InfluxDB,
                      Prometheus, StatsD)
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>Cloud Native Migration</td>
                  </tr>
                  <tr>
                    <td>Hybrid routing (legacy and cloud-native)</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Legacy infrastructure support</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Progressive migration model</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>GitOps-workflow ready</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>

                  {/* Section */}
                  <tr className="table-section-title">
                    <td colSpan={3}>Support services</td>
                  </tr>
                  <tr>
                    <td>Community forum</td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                    <td>
                      <div className="empty"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Business days &amp; 24/7 support options</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Direct access to the Traefik expert team</td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dedicated support team helps you manage and maintain your
                      environment
                    </td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Get notifications about new releases, updates, and fixes
                    </td>
                    <td>
                      <div className="empty"></div>
                    </td>
                    <td>
                      <div className="green-check"></div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td>
                      <a
                        href="https://docs.traefik.io/getting-started/install-traefik"
                        className="button--primary button--with-shadow"
                      >
                        Download
                      </a>
                    </td>
                    <td>
                      <button
                        onClick={() => setTraefikModal(true)}
                        className="button--primary button--with-shadow"
                      >
                        Get Pricing
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <div id="pricing-anchor-end"></div>

              <div
                className={
                  "pricing-table__sticky-footer" +
                  (tableStickyFooter ? " active" : "")
                }
                id="pricing-table-sticky-footer"
              >
                <div id="pricing-cell-empty" className="cell cell--empty"></div>
                <div id="pricing-cell-traefik" className="cell">
                  <a
                    href="https://docs.traefik.io/getting-started/install-traefik"
                    className="button--primary button--with-shadow"
                  >
                    Download
                  </a>
                </div>
                <div id="pricing-cell-traefikee" className="cell">
                  <button
                    onClick={() => setTraefikModal(true)}
                    className="button--primary button--with-shadow"
                  >
                    Get Pricing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pricing-customers page-content-section">
          <div className="wrapper-1200">
            <h2 className="title-uppercase">
              Loved &amp; Trusted by Leading Enterprises Worldwide
            </h2>
            <div className="pricing-customers__grid">
              <div className="item">
                <img src={customer__mozilla} alt="Mozilla" />
              </div>
              <div className="item">
                <StaticImage
                  src="../images/pricing/customers/ebay-classifieds/ebay-classifieds-groups.jpg"
                  alt="Ebay Classifieds Group"
                  width={116}
                  height={74}
                  layout="fixed"
                  placeholder="blurred"
                />
              </div>
              <div className="item">
                <img src={customer__condnast} alt="Condé Nast" />
              </div>
              <div className="item">
                <img src={customer__expedia} alt="Expedia" />
              </div>
              <div className="item">
                <img src={customer__talend} alt="talend" />
              </div>
              <div className="item">
                <img src={customer__bell} alt="Bell" />
              </div>
              <div className="item">
                <img src={customer__mailchimp} alt="mailchimp" />
              </div>
              <div className="item">
                <img src={customer__apple} alt="apple" />
              </div>
              <div className="item">
                <img src={customer__bose} alt="bose" />
              </div>
              <div className="item">
                <img src={customer__sainsbury} alt="sainsbury" />
              </div>
              <div className="item">
                <img src={customer__creditsuisse} alt="credit suisse" />
              </div>
              <div className="item">
                <img src={customer__sysdig} alt="sysdig" />
              </div>
              <div className="item">
                <img src={customer__ovh} alt="ovh cloud" />
              </div>
              <div className="item">
                <StaticImage
                  src="../images/pricing/customers/mirantis/mirantis.jpg"
                  alt="Mirantis"
                  width={116}
                  height={74}
                  layout="fixed"
                  placeholder="blurred"
                />
              </div>
              <div className="item">
                <img src={customer__newrelic} alt="New Relic" />
              </div>
              <div className="item">
                <StaticImage
                  src="../images/pricing/customers/rocket-chat/rocket-chat.jpg"
                  alt="Rocket Chat"
                  width={116}
                  height={74}
                  layout="fixed"
                  placeholder="blurred"
                />
              </div>
              <div className="item">
                <img src={customer__holidaycheck} alt="Holiday Check" />
              </div>
              <div className="item">
                <img src={customer__virginia} alt="Virginia Tech" />
              </div>
            </div>
          </div>
        </section>

        {/* FAQ */}
        <section className="pricing-faq page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Frequently Asked Questions</h2>
              <h4 className="title-uppercase">Still have questions?</h4>
              <button
                onClick={() => setAskQuestionModal(true)}
                className="button--primary button--with-shadow button--extra-padding"
              >
                Ask us a question
              </button>
            </div>

            <div className="pricing-faq__items">
              <div className="pricing-faq__item">
                <h3>Can I try Traefik Enterprise?</h3>
                <p>
                  Absolutely. You can start your 30-day trial{" "}
                  <a href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days">
                    here
                  </a>
                  .
                </p>
              </div>
              <div className="pricing-faq__item">
                <h3>How does your pricing work?</h3>
                <p>
                  We have a simple pricing model based on the number of proxy
                  instances and the criticality of your environment.
                </p>
              </div>
              <div className="pricing-faq__item">
                <h3>Do you provide special prices for startup companies?</h3>
                <p>
                  Yes, we do have special prices for early-stage startups.
                  Please contact us to know more.
                </p>
              </div>
              <div className="pricing-faq__item">
                <h3>
                  Is Traefik Enterprise compatible with both legacy and
                  cloud-native applications?
                </h3>
                <p>
                  Yes, Traefik Enterprise is designed for cloud-native
                  applications and compatible with legacy applications.
                </p>
              </div>
            </div>

            <div className="pricing-faq__for-mobile">
              <h4 className="title-uppercase">Still have questions? </h4>
              <button
                onClick={() => setAskQuestionModal(true)}
                className="button--primary button--with-shadow button--extra-padding"
              >
                Ask us a question
              </button>
            </div>
          </div>
        </section>

        {/* CTA */}
        <section className="pricing-cta page-footer">
          <div className="wrapper-1200">
            <h2>Start your journey today</h2>
            <div className="links">
              <a
                href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
                className="button--primary button--with-shadow button--extra-padding"
              >
                Traefik Enterprise Free Trial
              </a>
              <a
                href="https://info.traefik.io/contact-sales"
                className="button--silver button--with-shadow button--extra-padding"
              >
                Contact us
              </a>
            </div>
          </div>
        </section>
      </div>

      {/* TraefikEE Purchase Modal */}
      <div
        id="traefikee-purchase-modal"
        className={
          "modal modal--traefikee-purchase" + (traefikModal ? " active" : "")
        }
      >
        <div className="modal__container">
          <button
            onClick={() => setTraefikModal(false)}
            className="modal__close"
          >
            <CloseIcon />
          </button>

          <h2 className="modal__title">
            Interested in buying Traefik Enterprise?
          </h2>

          <p className="modal__sub-title">
            Please fill out the form below to get in touch with a Traefik Labs
            representative.
          </p>

          <div className="modal__content" id="traefikee-modal-content"></div>
        </div>
      </div>

      {/* Ask Question Modal */}
      <div
        id="ask-us-question-modal"
        className={
          "modal modal--ask-us-question" + (askQuestionModal ? " active" : "")
        }
      >
        <div className="modal__container">
          <button
            className="modal__close"
            onClick={() => setAskQuestionModal(false)}
          >
            <CloseIcon />
          </button>

          <h2 className="modal__title">Ask us a question</h2>

          <p className="modal__sub-title">
            Please fill out the form below to get in touch with a Traefik Labs
            representative.
          </p>

          <div
            className="modal__content"
            id="ask-us-question-modal-content"
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage

export const query = graphql`
  {
    current_page: ghostPage(slug: { eq: "pricing" }) {
      ...PageMetaFields
    }
  }
`
